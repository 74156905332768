import RaphaelScale from './vendors/scaleRaphael';
import States from './vendors/raphaelStates';

/**
 * Handles the resizing of the map container.
 *
 * @param {Object} mapInstance - An object that provides a changeSize method to adjust the dimensions of the map.
 * @param {Object} $map_container - A jQuery object representing the map container element.
 * @param {number} map_ratio - The aspect ratio (height/width) to maintain for the map.
 * @returns {Function|undefined} A function that handles the resize event, or undefined if any parameter is missing.
 */
function handleResize(mapInstance, $map_container, map_ratio) {
    // Check for required parameters
    if (mapInstance && $map_container && map_ratio) {
        // Return resize handler function
        return function() {
            let mapContainerWidth  = $map_container.width();
            if (mapContainerWidth > 0) {
                mapInstance.changeSize(mapContainerWidth, mapContainerWidth * map_ratio);
            }
        };
    }
    // Return undefined if parameters are invalid
    return undefined;
}

/**
 * @param {string} selector
 * @param {Array} activeStates activeStates is a string array with each element being state code lowercase ie: ca, fl, nv, az
 */
function initBrowseLocations(selector, activeStatesParam) {

    if (typeof selector !== 'string') {
        console.error('selector expected: ' + selector);
        return;
    }

    var $selector = $(selector);
    var $elem = $selector.length >= 1 ? $($selector[0]) : $selector;

    let active_states = [];
    if (activeStatesParam === undefined)
    {
        const stateElements = $elem.find('[data-state]');
        stateElements.each((i,s) => {
            const stateCode = s.getAttribute('data-state-code');
            active_states.push(stateCode);
        });
    } else {
        active_states = activeStatesParam;
    }

    const USA_MAP = $elem.find('#usa-map');
    if(typeof(USA_MAP) != 'undefined' && USA_MAP != null) {
        
        //Call Raphael Scale from JS file.
        RaphaelScale(USA_MAP[0]);
        
        // draw map
        var $map_container = $elem.find("#map-container"),
        map_ratio = .6,
        state_url = "#",
        mapInstance = new ScaleRaphael("usa-map", 970, 550),
        attr = {
                "stroke": "#fff",
                "stroke-opacity": "1",
                "stroke-linejoin": "round",
                "stroke-miterlimit": "4",
                "stroke-width": "1.2",
                "stroke-dasharray": "none",
                "cursor": 'pointer'
            },
        usRaphael = {};

        //draw map and store Raphael paths
        for (let state in States) {
            // color active states purple
            if ($.inArray(state, active_states) > -1) {
                attr.class = 'active-state';
            } else {
                attr.class = 'inactive-state';
            }

            attr.state = state;
            usRaphael[state] = mapInstance.path(States[state].path).attr(attr).data("state", state);
            usRaphael['label'] = [];

            // add labels
            let pos = usRaphael[state].getBBox();
            usRaphael['label'][state] = mapInstance.text(
                pos.x + (pos.width / 2) + States[state].text.x,
                pos.y + (pos.height / 2 + States[state].text.y),
                state.toUpperCase()
            ).data("state", state);
            usRaphael['label'][state].toFront();

            // bind click
            usRaphael[state].click(function(e) {
                e.preventDefault();
                let clickedState = this.data("state");
                //window.location = state_url + this.data("state").toUpperCase();
                if(this[0].classList.contains('active-state')) {
                    const clearPaths = $elem.find('path'),
                        myPath = this[0].classList,
                        activeClickedState = $elem.find('.state-title.bl-'+clickedState+',#mapView ul.bl-'+clickedState+'');
                    clearPaths.removeClass('clicked');
                    myPath.add('clicked');
                    $elem.find('.region-container').css({'display': 'block'});
                    //Clear all lists
                    $elem.find('#mapView .state-title,#mapView ul').removeClass('active');

                    //Show selected list
                    activeClickedState.addClass('active');
                }
                
            });

            if (typeof States[state].text.hide !== 'undefined' && States[state].text.hide === true)
                usRaphael['label'][state].remove();
        }

        // Call handleResize to get the resize handler function for the map container
        const resizeHandler = handleResize(mapInstance, $map_container, map_ratio);
        
        // Attach the resize handler function to the window's resize event to adjust the map size dynamically when the window is resized
        $(window).on("resize", resizeHandler);

        // Immediately call the resize handler function to set the initial size of the map container
        resizeHandler();
    }
}

window.initBrowseLocations = initBrowseLocations;
window.isBrowseLocationsJSLoaded = true;
var browseLocationsJSLoaded = new CustomEvent("browseLocationsJSLoaded", {
    detail: {
      message: "browseLocations js loaded"
    }
  });
window.dispatchEvent(browseLocationsJSLoaded);
